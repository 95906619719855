.category-page {}

.category-page__back-btn {
    position: fixed;
    width: 40px;
    height: 40px;
    top: 15px;
    right: 12px;
    border-radius: 9px;
    background-color: rgb(255, 255, 255, 0.8);

    &:hover {
        cursor: pointer;
    }
}

.category-page-content {
    display: grid;
    grid-column-gap: 30px;
    grid-row-gap: 20px;
    grid-template-columns: repeat(4, 1fr);
    z-index: 1;
    padding-top: 10px;
    padding-bottom: 20px;

    @media (max-width: "1140px") {
        grid-template-columns: repeat(3, 1fr);
    }

    @media (max-width: "680px") {
        grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: "580px") {
        grid-column-gap: 10px;
        grid-row-gap: 8px;
    }

    // @media (max-width: "580px") {
    //     grid-template-columns: repeat(1, 1fr);
    //     justify-items: center;
    //     grid-row-gap: 20px;
    // }
}

.category-page__text {

    margin-bottom: 40px;

    p {
        text-indent: 40px;
        text-align: justify;
        line-height: 26px;

        @media (max-width: "580px") {
            margin-top: 0;
        }
    }
}
    
.filters {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    margin-top: 15px;
    margin-bottom: 5px;

    &__input {
        height: 20px;
        width: 20px;
        margin-left: 10px;
    }
}