article {

    max-width: 360px;
    // min-width: 230px;

    a {

        img {
            object-fit: cover;
            height: 80%;
            width: 100%;
            border-radius: 5px;
            box-shadow: rgba(0, 0, 0, 0.5) 0px 3px 8px;
            transition: .2s;
        }
    }

    h3 {
        text-align: center;
        // color: #E5A908;
        color: black;
        text-decoration: none;
        font-weight: 400;
        font-size: 16px;

        @media (max-width: "800px") {
            margin-top: 6px;
        }
    }

    :hover {
        img {
            box-shadow: rgba(0, 0, 0, 0.8) 0px 3px 10px;
        }
    }

    @media (max-width: "1050px") {
        h3 {
            font-size: 14px;
        }
    }

    @media (max-width: "450px") {
        max-width: 260px;
    }
}

.navigation {
    &__nav {}

    &__list {}

    &__item {}

    &__link {}
}