.main {}

.page__title {
    color: black;
    text-decoration: none;
    font-weight: 500;
    font-size: 18px;
    text-align: center;
    margin-top: 24px;

    @media (max-width: "1050px") {
        font-size: 16px;   
    }
}

.page__text {
    font-size: 16px;

    @media (max-width: "1050px") {
        font-size: 14px;   
    }
}

.cnt {
    display: flex;
    justify-content: center;
}

.content-cnt {
    width: 90%;
    max-width: 1300px;
    z-index: 1;

    // @media (max-width: "800px") {
    //     width: 96%;    
    // }

}

.main__categories {
    width: 100%;
    display: grid;
    grid-column-gap: 30px;
    grid-template-columns: repeat(4, 1fr);
    z-index: 1;
    padding-top: 10px;

    @media (max-width: "1140px") {
        grid-template-columns: repeat(3, 1fr);
    }

    @media (max-width: "680px") {
        grid-template-columns: repeat(2, 1fr);
        grid-row-gap: 8px;
    }

    // @media (max-width: "580px") {
    //     grid-template-columns: repeat(1, 1fr);
    //     justify-items: center;
    // }
}