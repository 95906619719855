.card {
    // height: 450px;
    // max-width: 450px;
    max-width: 300px;
    // min-width: 230px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    overflow: hidden;
    

    &:hover {
        cursor: pointer;

        .card__img {
            box-shadow: rgba(0, 0, 0, 0.8) 0px 3px 10px;
        }

        .card__text {
            font-weight: 500;
        }
    }

    // @media (max-width: "580px") {
    //     width: 360px;
    // }

    // @media (max-width: "450px") {
    //     max-width: 300px;
    // }
}

.card__img {
    width: 90%;
    margin-top: 10px;
    object-fit: cover;
    transition: .2s;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.5) 0px 3px 8px;
}

.card__text-block {
    display: flex;
    flex-direction: column;
    align-items: center;
    // height: 50px;
    margin-top: 10px;
}

.card__text {
    font-weight: 400;
    width: 100%;
    text-align: center;
    font-size: 16px;
    margin-top: 5px;
}

@media (max-width: "1050px") {
    .card__text {
        font-size: 14px;
    }
}

.card__price {
    font-weight: 500;
}