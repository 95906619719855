@keyframes animate-pulse {
    0% {
        transform: scale3d(1, 1, 1);
    }

    50% {
        transform: scale3d(0.95, 0.95, 0.95);
    }

    100% {
        transform: scale3d(1, 1, 1);
    }
}

.modal {
    opacity: 1;
    pointer-events: all;
    height: 100vh;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
    backdrop-filter: blur(5px);

    &__content {
        display: flex;
        gap: 25px;
        position: relative;
        max-width: 90%;
        max-height: 90%;
        padding: 25px;
        border-radius: 12px;
        background-color: white;
        transition: 0.4s all;
        overflow-y: auto;
        overflow-x: hidden;
        align-items: center;

        @media (min-width: "1150px") {
            align-items: flex-start;
        }

        @media (max-width: "1050px") {
            flex-direction: column;
            width: 100%;
            height: 95%;
            max-height: none;
        }

        // @media (max-width: "560px") {
        //     padding: 25px;
        //     padding-right: 10px;
        //     padding-left: 10px;
        //     max-width: 90%;
        //     max-height: 90%;
        // }

        .close-button {
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            right: 11px;
            top: 15px;
            width: 40px;
            height: 40px;
            z-index: 1;
            border-radius: 6px;
            background-color: rgb(255, 255, 255, 0.8);

            &:hover {
                cursor: pointer;
            }
        }

        .close-button::before {
            content: "";
            position: absolute;
            width: 24px;
            height: 4px;
            background: black;
            transform: rotate(45deg);
        }

        .close-button::after {
            content: "";
            position: absolute;
            width: 24px;
            height: 4px;
            background: black;
            transform: rotate(-45deg);
        }
    }

    &__desktop-img {
        object-fit: cover;
        width: 50%;

        @media (max-width: "1150px") {
            display: none;
        }
    }

    // @media (max-width: "1150px") {
    //     width: 80%;
    // }


    &__description-block {
        display: flex;
        flex-direction: column;
        max-width: 650px;

        h2 {
            margin: 0;
        }

        p {
            margin-top: 0;
            line-height: 25px;
            color: #4c4c4c;
            font-weight: 500;
            text-align: justify;

            @media (max-width: "560px") {
                font-size: 16px;
            }

            margin-top: 15px;
        }

        p.no-margin {
            margin: 0;
            color: black;
        }

        .modal__price {
            font-size: 20px;
            font-weight: 400;
            margin-top: 10px;
            margin-bottom: 0;
            text-align: center;
        }

        .modal__name {
            text-align: center;
        }

        .modal__order {
            display: flex;
            align-items: center;
            gap: 15px;
            margin-bottom: 15px;
            align-self: center;

            p {
                margin: 0;
                font-weight: 500;
                color: black;

                animation: animate-pulse 1s infinite;
            }
        }

        .modal__social-media-item {
            border: none;
            background: none;
            width: 40px;
            padding: 0;

            &:hover {
                cursor: pointer;
            }

            img {
                width: 100%;
                object-fit: contain;
            }
        }

        .modal__mobile-img {
            display: none;
            justify-content: center;
            max-width: 100%;
            overflow: hidden;

            @media (max-width: "1150px") {
                display: flex;
            }
        }
    }

}

.modal__loading {
    position: absolute;
    height: 100vh;
    width: 100vw;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    backdrop-filter: blur(3px);
    z-index: 2;
}

.modal__loading-text {
    margin: 0;
    font-weight: 500;
    font-size: 18px;
}

.text-indent {
    text-indent: 30px;
}

@media (max-width: "1050px") {
    .modal__name {
        font-size: 16px;
    }

    .modal__description-block .modal__price {
        font-size: 16px;
    }

    .modal__description-block p {
        font-size: 14px;
    }
}