.header {
    // background-color: rgb(171, 166, 166);
    // background-image: url("../../main-background1.jpg");
    display: flex;
    height: 85px;
    overflow: hidden;
    background-color: white;
    transition: .3s;
    position: sticky;
    top: 0px;
    z-index: 2;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 3px 3px;
}

.header.cnt {
    @media (max-width: "1050px") {
        display: none;
    }
}

.header__logo-cnt {
    display: flex;
    align-items: center;
    gap: 30px;
}

.header__logo-cnt-link {
    @media (max-width: "450px") {
        // display: none;
    }
}

.header__flowwow {
    display: none;
    align-items: center;
    cursor: pointer;
    text-decoration: none;
    color: #fa4d1e;
    transition: .2s;

    &:hover {
        transform: scale(1.02);
    }

    p {
        font-weight: 600;
        margin-right: 10px;
    }
}

.header-content-cnt {
    display: flex;
    align-items: center;
    column-gap: 30px;
    justify-content: space-between;

    img {
        width: 120px;
        object-fit: contain;
    }
}

.after-scrolled.hidden {
    position: sticky;
    top: -144px;
}