.social-media {
    display: none;
}

.social-media.desktop {
    display: flex;
    position: static;
    flex-direction: row;
    column-gap: 20px;
}

.social-media.mobile {
    position: fixed;
    column-gap: 20px;
    right: 20px;
    bottom: 40px;
    z-index: 1;
    border-radius: 10px;
    padding: 5px;

}

@media (max-width: "1050px") {
    .social-media.mobile {
        display: flex;
    }
}

.social-media__item {
    height: 40px;
    width: 40px;

    .social-media__item-img {
        width: 100%;
        object-fit: cover;
    }
}