.menu-cnt {
    display: none;
    position: fixed;
    height: 100vh;
    width: 300px;
    top: 0;
    left: 0;
    z-index: 10;
    transform: translate(-100%);
    transition: all 0.2s;

    &__background {
        position: absolute;
        width: 100vw;
        height: 100vh;
        left: 0;
        top: 0;
        z-index: 0;
        pointer-events: none;
    }

    @media (max-width: "1000px") {
        display: flex;
    }
}

.menu-cnt.active {
    transform: translate(0);

    .menu__menu-btn {
        right: 16px;
    }

    .menu-cnt__background {
        pointer-events: all;
    }
}

.menu {
    position: relative;
    width: 250px;
    height: 100%;
    left: 0;
    top: 0;
    background-color: white;
    padding: 20px;
    box-shadow: rgba(0, 0, 0, 0.5) 0px 0px 10px;

    &__menu-btn {
        position: absolute;
        top: 15px;
        right: -60px;
        width: 40px;
        height: 40px;
        transition: all 0.2s;
        border-radius: 6px;
        background-color: rgb(255, 255, 255, 0.8);

        &:hover {
            cursor: pointer;
        }
    }

    &__nav {}

    &__list {
        margin: 0;
        padding: 0;
    }

    &__item {
        list-style: none;
        padding-top: 10px;
        padding-bottom: 10px;
        border-bottom: 1px solid black;
    }

    &__link {
        font-size: 16px;
        text-decoration: none;
        color: black;
    }
}