.slider {
    position: relative;
    background-repeat: no-repeat;
    background-position: center;
    height: 80vh;
    width: 30vw;
    display: flex;
    align-items: center;
    justify-content: center;
}

.modal__mobile-img .slider {
    width: 100%;
    height: 50vh;
    min-height: 300px;
    margin-top: 10px;

    img {
        height: 100%;
        object-fit: cover;
    }
}

.modal__desktop-img .slider {
    min-height: 400px;

    img {
        width: 100%;
    }
}

// .slider__content {
//     width: 96%;
//     display: flex;
//     justify-content: space-between;
// }

.slider__points {
    position: absolute;
    bottom: 0;
    // margin-left: auto;
    // margin-right: auto;
    // left: 0;
    // right: 0;
    display: flex;
    justify-content: center;
    column-gap: 10px;
}

.modal__mobile-img .slider__points {
    column-gap: 30px;
}

.slider__point {
    height: 12px;
    width: 12px;
    border-radius: 50%;
    border: 1px solid black;

    &:hover {
        cursor: pointer;
    }
}

.modal__mobile-img .slider__point {
    height: 20px;
    width: 20px;
}


.slider__point.active {
    background-color: black;
}

.slider__btn {
    position: absolute;
    width: 40px;
    height: 40px;
    padding: 0;
    border: none;
    background: none;
    transition: .2s;

    img {
        height: 100%;
    }

    &:hover {
        cursor: pointer;
        transform: scale(1.05);
    }
}

.slider__btn--left {
    left: 0;

    img {
        transform: rotate(180deg);
    }
}

.slider__btn--right {
    right: 0;
}