@import url('https://rsms.me/inter/inter.css');

html {
    font-family: 'Inter', sans-serif;
}


body {
    margin: 0;
    width: 100%;
}

#root {
    
}