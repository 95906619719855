.main-content {
    position: relative;
}

.main-content__background {
    position: absolute;
    height: 100%;
    width: 100%;
    // background-image: url("./main-background1.jpg");
    // background-color: #f8f5f5;
    filter: blur(3px);
    z-index: 0;
}