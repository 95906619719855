.navigation {
    
}

.navigation-list {
    display: flex;
    column-gap: 30px;
    padding: 0;
    margin: 0;
}

.navigation-item {
    display: flex;
    justify-content: center;
    align-items: center;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 80%;
    list-style-type: none;
    // border:  1px solid black;
}

.navigation-link {
    // color: #E5A908;
    color: black;
    text-decoration: none;
    font-weight: 400;
    font-size: 18px;

    @media (max-width: "1050px") {
        font-size: 16px;
    }
}

.navigation-link.active {
    font-weight: 500;
}